/* eslint-disable complexity, max-statements */

import { Map, fromJS } from 'immutable';
import moment from 'moment-business-days';

import { getAssetStatusFromGQLType } from 'modules/assets/helpers';
import {
  ASSET_OVERVIEW,
  AssetOverviewAction,
  filterDate,
  receivedDetailsData,
  receivedDetailsDataMobile,
  receivedUsageData,
  updateCache,
} from '../actions';
import {
  EvolutionDataType,
  GraphQLAverageHourlyConsumption,
  GraphQLDailyCO2History,
  GraphQLDailyFuelHistory,
  GraphQLDailyHourHistory,
} from '../types';
import { AssetState } from './types/AssetState';

const _since = moment().subtract(7, 'day').format('YYYY-MM-DD');
const _until = moment().format('YYYY-MM-DD');

const initialAssetState: AssetState = {
  // identity data
  assetId: null,
  owned: null,
  status: null,
  name: null,
  make: null,
  model: null,
  modelManufacturer: null,
  serialNumber: null,
  description: null,
  category: null,
  categories: [],
  lastDataDate: null,
  latestPosition: null,
  lastUpdatedAt: null,
  source: null,
  dataSources: [],
  year: null,
  enterprise: null,

  cumulativeUsageHoursDate: null,
  cumulativeUsageHours: null,
  cumulativeFuelUsedDate: null,
  cumulativeFuelUsed: null,
  cumulativeDistanceDate: null,
  cumulativeDistance: null,
  cumulativePayloadTotal: null,
  cumulativeLoadCount: null,
  cumulativePowerTakeOffHours: null,

  payloadTotal: null,
  payloadTotalDate: null,
  loadCount: null,
  loadCountDate: null,
  powerTakeOff: null,
  powerTakeOffDate: null,
  energyType: null,
  batteryIndicator: null,

  isDataMissing: false,

  // filters
  since: _since,
  until: _until,
  nbWorkingDays: null,

  // raw data from usage query
  faults: [],

  // aggregated data ready for displaying usage
  faultsReport: {
    total: null,
    lowNb: null,
    mediumNb: null,
    highNb: null,
    criticalNb: null,
  },
  exploitation: {
    usageHours: null,
    operatingHours: null,
    idleHours: null,
    fuelUsed: null,
    co2: null,
  },
  efficiency: {
    nbDays: null,
    usagePercent: null, // taux d'utilisation
    idlePercent: null, // taux de ralenti
    averageHourlyConsumption: null,
    output: null, // "rendement"
  },
  evolution: {
    data: null,
  },
};

const initialState: Map<keyof AssetState, any> = fromJS(initialAssetState);

function computeRatio(numerator: number | null, denominator: number | null) {
  if (numerator == null || denominator == null || denominator <= 0) {
    return null;
  }
  return numerator / denominator;
}

function craftCO2Aggregates(dailyCO2History: GraphQLDailyCO2History[]) {
  let co2: number | null = null;
  dailyCO2History.forEach((dailyCO2) => {
    if (co2 === null) {
      co2 = 0;
    }
    co2 += dailyCO2.kilograms;
  });
  return co2;
}

function craftFuelHistoryAggregates(
  dailyFuelHistory: GraphQLDailyFuelHistory[],
) {
  const fuelUsedGraphData: any = [];
  let fuelUsed: number | null = null;
  dailyFuelHistory.forEach((df) => {
    if (fuelUsed === null) {
      fuelUsed = 0;
    }
    fuelUsed += df.liters;

    fuelUsedGraphData.push({
      date: df.date,
      fuelUsed: df.liters,
    });
  });
  return {
    fuelUsed,
    fuelUsedGraphData,
  };
}

function craftDailyHourHistoryAggregates(
  nbWorkingDays: number,
  dailyHourHistory: GraphQLDailyHourHistory[],
) {
  let nbDays: number | null = null;
  let usageHours: number | null = null;
  let operatingHours: number | null = null;
  let idleHours: number | null = null;

  const usageHoursGraphData: EvolutionDataType[] = [];

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
  dailyHourHistory.forEach((dh) => {
    if (dh.usageHours && dh.usageHours > 0) {
      if (nbDays === null) {
        nbDays = 0;
      }
      nbDays += 1;
      if (usageHours === null) {
        usageHours = 0;
      }
      usageHours += dh.usageHours;
    }
    if (dh.operatingHours !== null) {
      if (operatingHours === null) {
        operatingHours = 0;
      }
      operatingHours += dh.operatingHours;
    }
    if (dh.idleHours !== null) {
      if (idleHours === null) {
        idleHours = 0;
      }
      idleHours += dh.idleHours;
    }

    usageHoursGraphData.push({
      date: dh.date,
      usageHours: dh.usageHours,
      idleHours: dh.idleHours,
      operatingHours: dh.operatingHours,
    });
  });

  let usagePercent = null;
  if (nbDays != null && nbWorkingDays != null) {
    if (nbDays === 0) {
      usagePercent = 0;
    } else if (nbWorkingDays === 0) {
      // should not happen
      // let usagePercent as null
    } else {
      usagePercent = Math.min(nbDays / nbWorkingDays, 1);
    }
  }
  return {
    nbDays,
    usageHours,
    operatingHours,
    idleHours,
    usageHoursGraphData,
    usagePercent,
  };
}

function craftAggregates(
  dailyHourHistory: GraphQLDailyHourHistory[],
  dailyFuelHistory: GraphQLDailyFuelHistory[],
  dailyCO2History: GraphQLDailyCO2History[],
  nbWorkingDays: number,
  averageHourlyConsumption: GraphQLAverageHourlyConsumption | null,
) {
  const co2: number | null = craftCO2Aggregates(dailyCO2History);
  const { fuelUsed, fuelUsedGraphData } =
    craftFuelHistoryAggregates(dailyFuelHistory);
  const {
    nbDays,
    usageHours,
    operatingHours,
    idleHours,
    usageHoursGraphData,
    usagePercent,
  } = craftDailyHourHistoryAggregates(nbWorkingDays, dailyHourHistory);

  return {
    exploitation: {
      usageHours,
      operatingHours,
      idleHours,
      fuelUsed,
      co2,
    },
    efficiency: {
      usagePercent,
      idlePercent: computeRatio(
        idleHours,
        (idleHours || 0) + (operatingHours || 0),
      ),
      averageHourlyConsumption: averageHourlyConsumption?.litersPerHour || null,
      nbDays,
      output: computeRatio(usageHours, nbDays),
    },
    evolution: {
      data: usageHoursGraphData,
      fuel: fuelUsedGraphData,
    },
  };
}
function updateStateWithAssetUsage(
  action: ReturnType<typeof receivedUsageData>,
  state: Map<keyof AssetState, any>,
) {
  const { workspace } = action.data.viewer;
  const { asset } = workspace;

  if (!asset) {
    return state;
  }

  const {
    dailyCO2History,
    dailyHourHistory,
    dailyFuelHistory,
    workingDaysCount,
  } = asset;
  const { exploitation, efficiency, evolution } = craftAggregates(
    dailyHourHistory,
    dailyFuelHistory,
    dailyCO2History,
    workingDaysCount,
    asset.averageHourlyConsumption,
  );

  const isDataMissing =
    state.get('isDataMissing') ||
    dailyHourHistory.length < workingDaysCount ||
    dailyFuelHistory.length < workingDaysCount;
  const latestCumulativeFuel = asset.latestCumulativeFuel || {
    liters: null,
    date: null,
  };

  const latestCumulativeDistance = asset.latestCumulativeDistance || {
    kilometers: null,
    date: null,
  };

  const latestCumulativeUsageHour = asset.latestCumulativeUsageHour || {
    hours: null,
    date: null,
  };

  const latestCumulativePayload = asset.latestCumulativePayload || {
    tonnes: null,
    date: null,
  };

  const latestCumulativeLoadCount = asset.latestCumulativeLoadCount || {
    count: null,
    date: null,
  };

  const latestCumulativePowerTakeOffHour =
    asset.latestCumulativePowerTakeOffHour || {
      hours: null,
      date: null,
    };

  return state
    .set('exploitation', fromJS(exploitation))
    .set('efficiency', fromJS(efficiency))
    .set('evolution', fromJS(evolution))
    .set('isDataMissing', isDataMissing)
    .set('cumulativeFuelUsed', latestCumulativeFuel.liters)
    .set('cumulativeFuelUsedDate', latestCumulativeFuel.date)
    .set('cumulativeDistance', latestCumulativeDistance.kilometers)
    .set('cumulativeDistanceDate', latestCumulativeDistance.date)
    .set('cumulativeUsageHours', latestCumulativeUsageHour.hours)
    .set('cumulativeUsageHoursDate', latestCumulativeUsageHour.date)
    .set('payloadTotal', latestCumulativePayload.tonnes)
    .set('payloadTotalDate', latestCumulativePayload.date)
    .set('loadCount', latestCumulativeLoadCount.count)
    .set('loadCountDate', latestCumulativeLoadCount.date)
    .set('powerTakeOff', latestCumulativePowerTakeOffHour.hours)
    .set('powerTakeOffDate', latestCumulativePowerTakeOffHour.date)
    .set('nbWorkingDays', workingDaysCount);
}

function updateStateWithAssetDetailsData(
  action: ReturnType<typeof receivedDetailsData>,
  state: Map<keyof AssetState, any>,
) {
  const { workspace } = action.data.viewer;

  const { asset } = workspace;

  if (!asset) {
    return state;
  }

  const { categories } = action.data.viewer.organization;

  const {
    batteryLevel,
    category,
    latestCumulativeFuel,
    latestCumulativeDistance,
    latestCumulativeUsageHour,
    latestCumulativePayload,
    latestCumulativeLoadCount,
    latestCumulativePowerTakeOffHour,
    dataSources,
    energyType,
    enterprise,
    id,
    metadata,
    owned,
    lastDataDate,
    latestPosition,
    make,
    model,
    modelManufacturer,
    name,
    serialNumber,
    source,
    year,
  } = asset;
  const status = getAssetStatusFromGQLType(metadata.__typename);

  const { indicator } = batteryLevel || { indicator: '' };

  let distance = null;
  let distanceDate = null;
  let fuelUsed = null;
  let fuelUsedDate = null;
  let usageHours = null;
  let usageHoursDate = null;
  let payloadTotal = null;
  let payloadTotalDate = null;
  let loadCount = null;
  let loadCountDate = null;
  let powerTakeOff = null;
  let powerTakeOffDate = null;
  if (latestCumulativeFuel) {
    fuelUsed = latestCumulativeFuel.liters;
    fuelUsedDate = latestCumulativeFuel.date;
  }
  if (latestCumulativeDistance) {
    distance = latestCumulativeDistance.kilometers;
    distanceDate = latestCumulativeDistance.date;
  }
  if (latestCumulativeUsageHour) {
    usageHours = latestCumulativeUsageHour.hours;
    usageHoursDate = latestCumulativeUsageHour.date;
  }
  if (latestCumulativePayload) {
    payloadTotal = latestCumulativePayload.tonnes;
    payloadTotalDate = latestCumulativePayload.date;
  }
  if (latestCumulativeLoadCount) {
    loadCount = latestCumulativeLoadCount.count;
    loadCountDate = latestCumulativeLoadCount.date;
  }
  if (latestCumulativePowerTakeOffHour) {
    powerTakeOff = latestCumulativePowerTakeOffHour.hours;
    powerTakeOffDate = latestCumulativePowerTakeOffHour.date;
  }

  const isDataMissing =
    source !== 'tripy' &&
    source !== 'marlink' &&
    (!!state.get('isDataMissing') ||
      !latestCumulativeFuel ||
      !latestCumulativeFuel.id ||
      !latestCumulativeUsageHour ||
      !latestCumulativeUsageHour.id);

  return state
    .set('assetId', id)
    .set('name', name)
    .set('owned', owned)
    .set('status', status)
    .set('categories', fromJS(categories))
    .set('category', fromJS(category))
    .set('energyType', fromJS(energyType))
    .set('lastDataDate', lastDataDate)
    .set('latestPosition', fromJS(latestPosition))
    .set('batteryIndicator', indicator)
    .set('source', source)
    .set('dataSources', fromJS(dataSources))
    .set('make', make)
    .set('serialNumber', serialNumber)
    .set('model', model)
    .set('modelManufacturer', modelManufacturer)
    .set('year', year)
    .set('enterprise', enterprise)
    .set('cumulativeUsageHours', usageHours)
    .set('cumulativeUsageHoursDate', usageHoursDate)
    .set('cumulativeFuelUsed', fuelUsed)
    .set('cumulativeFuelUsedDate', fuelUsedDate)
    .set('cumulativeDistance', distance)
    .set('cumulativeDistanceDate', distanceDate)
    .set('payloadTotal', payloadTotal)
    .set('payloadTotalDate', payloadTotalDate)
    .set('loadCount', loadCount)
    .set('loadCountDate', loadCountDate)
    .set('powerTakeOff', powerTakeOff)
    .set('powerTakeOffDate', powerTakeOffDate)
    .set('isDataMissing', isDataMissing);
}

function updateStateWithAssetDetailsDataMobile(
  action: ReturnType<typeof receivedDetailsDataMobile>,
  state: Map<keyof AssetState, any>,
) {
  const { workspace } = action.data.viewer;
  const { asset } = workspace;

  if (!asset) {
    return state;
  }

  const {
    batteryLevel,
    category,
    dataSources,
    enterprise,
    id,
    metadata,
    owned,
    lastDataDate,
    latestPosition,
    make,
    model,
    modelManufacturer,
    name,
    serialNumber,
    source,
    year,
  } = asset;
  const status = getAssetStatusFromGQLType(metadata.__typename);

  const { indicator } = batteryLevel || { indicator: '' };

  return state
    .set('assetId', id)
    .set('owned', owned)
    .set('status', status)
    .set('batteryIndicator', indicator)
    .set('category', fromJS(category))
    .set('dataSources', fromJS(dataSources))
    .set('enterprise', enterprise)
    .set('lastDataDate', lastDataDate)
    .set('latestPosition', fromJS(latestPosition))
    .set('make', make)
    .set('model', model)
    .set('modelManufacturer', modelManufacturer)
    .set('name', name)
    .set('serialNumber', serialNumber)
    .set('source', source)
    .set('year', year);
}

function updateStateWithAssetOverviewFilterDate(
  action: ReturnType<typeof filterDate>,
  state: Map<keyof AssetState, any>,
) {
  const { since, until } = action;

  return state
    .set('since', moment(since).format('YYYY-MM-DD'))
    .set('until', moment(until).format('YYYY-MM-DD'))
    .set('isDataMissing', false);
}

function updateStateWithAssetCache(
  action: ReturnType<typeof updateCache>,
  state: any,
) {
  const {
    category,
    enterprise,
    make,
    model,
    modelManufacturer,
    name,
    year,
    energyType,
  } = action.data;

  return state
    .set('category', fromJS(category))
    .set('energyType', fromJS(energyType))
    .set('make', make)
    .set('name', name)
    .set('model', model)
    .set('modelManufacturer', modelManufacturer)
    .set('year', year)
    .set('enterprise', enterprise);
}

export function assetOverviewReducer(
  // biome-ignore lint/style/useDefaultParameterLast: <not sure how this reducer is called>
  state: Map<keyof AssetState, any> = initialState,
  action: AssetOverviewAction,
) {
  switch (action.type) {
    case ASSET_OVERVIEW.RECEIVED_USAGE_DATA: {
      return updateStateWithAssetUsage(action, state);
    }

    case ASSET_OVERVIEW.RECEIVED_DETAILS_DATA: {
      return updateStateWithAssetDetailsData(action, state);
    }

    case ASSET_OVERVIEW.RECEIVED_DETAILS_DATA_MOBILE: {
      return updateStateWithAssetDetailsDataMobile(action, state);
    }

    case ASSET_OVERVIEW.FILTER_DATE: {
      return updateStateWithAssetOverviewFilterDate(action, state);
    }

    case ASSET_OVERVIEW.CLOSE_WARNING: {
      return state.set('isDataMissing', false);
    }

    case ASSET_OVERVIEW.UPDATE_CACHE: {
      return updateStateWithAssetCache(action, state);
    }

    default:
      return state;
  }
}
