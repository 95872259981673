import { AssetFileFormData } from 'modules/assets/files/components/AssetFileFormModal';
import { AssetFiltersGQL as InventoryAssetFilters } from 'modules/common-ui/hooks/assetFiltersGQL/types';
import { FetchApi } from '../client';

export type RequestExportInventoryParams = {
  filters: InventoryAssetFilters;
  search: string;
  columns: string[];
};

export type RequestExportArchivedInventoryParams = {
  search: string;
  columns: string[];
};

export const fleetApi = (fetchApi: FetchApi) => ({
  downloadAssetInOrganizationFile(
    token: string,
    assetInOrganizationFileId: number,
    onCompleted: (res: Response) => void,
    onError: () => void,
  ): Promise<void> {
    const endpoint = fetchApi.generateEndpoint(
      `/app/assetInOrganizationFiles/${assetInOrganizationFileId}/download`,
    );

    return fetch(endpoint, {
      method: 'GET',
      headers: {
        'x-access-token': token,
      },
    })
      .then(onCompleted)
      .catch(onError);
  },

  uploadAssetInOrganizationFile(
    token: string,
    assetId: number,
    formData: AssetFileFormData,
  ): Promise<void> {
    const { file, shared, isPublic, name } = formData;
    return fetchApi
      .setToken(token)
      .postFile(
        '/app/assetInOrganizationFiles/upload',
        { assetId, shared, isPublic, name },
        file,
      );
  },
  requestExportInventory(
    token: string,
    params: RequestExportInventoryParams,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/inventory/exportV2', params)
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occured while requesting your export. Please try again later',
        );
      });
  },
  requestExportArchivedInventory(
    token: string,
    params: RequestExportArchivedInventoryParams,
  ): Promise<void> {
    return fetchApi
      .setToken(token)
      .post('/app/inventory/exportV2/archived', { ...params, filters: {} })
      .catch((errorMessage) => {
        throw new Error(
          (typeof errorMessage === 'string' && errorMessage) ||
            'An error occured while requesting your export. Please try again later',
        );
      });
  },
});
