import {
  AssetFound,
  AssetFoundStatus,
} from 'modules/global-search/models/AssetFound.model';
import { AssetSearchFragment } from '../getGlobalSearch/gql.generated';

export const assetSearchResultToAssetFound = (row: AssetSearchFragment) => {
  const fields = {
    id: Number(row.id),
    name: row.name,
    make: row.make || '',
    model: row.model || '',
    status:
      row.status === 'ARCHIVED'
        ? AssetFoundStatus.ARCHIVED
        : AssetFoundStatus.ACTIVE,
    category:
      row.category && row.category.id
        ? {
            id: Number(row.category.id),
            name: row.category.name || '',
            color: row.category.color || '',
          }
        : null,
    sharingInfos: row.sharingInfos
      ? {
          sharedBy: row.sharingInfos.sharedBy
            ? {
                id: Number(row.sharingInfos.sharedBy.id),
                organizationName: row.sharingInfos.sharedBy.organizationName,
                iconUrl: row.sharingInfos.sharedBy.iconUrl || null,
              }
            : null,
          sharedWith: row.sharingInfos.sharedWith.map((sharedWith) => ({
            id: Number(sharedWith.id),
            organizationName: sharedWith.organizationName,
          })),
        }
      : null,
  };
  return new AssetFound(fields);
};
