import React, { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { EllipsisIcon, Tooltip } from 'modules/common-ui';

import {
  ActionItem,
  ActionsListContainer,
  ActionsWrapper,
  IconLeftWrapper,
  IconRightWrapper,
  IconWrapper,
} from './index.css';

export type ActionType = {
  id: string;
  label: string;
  onClick(): void;
  variant?: 'danger' | 'primary';
  display?: boolean;
  disabled?: boolean;
  tooltipMessage?: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  optionStyle?: React.CSSProperties;
};

type ActionsProps = {
  actions: ActionType[];
};

export const ActionsCell = ({ actions }: ActionsProps) => {
  const [showActions, setShowActions] = useState(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const menu = menuRef.current;

    if (menu) {
      const menuPosition = menu.getBoundingClientRect();
      const tablePosition = document
        .querySelector('.table-wrapper')
        ?.getBoundingClientRect();

      if (tablePosition) {
        const isOverflow =
          menuPosition.height + menuPosition.y >
          tablePosition.height + tablePosition.y;

        if (isOverflow) {
          menu.style.bottom = '0';
        }
      }
    }
  }, [showActions]);

  const renderActions = () => {
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setShowActions(false);
        }}
      >
        <ActionsListContainer ref={menuRef}>
          {actions
            .filter(({ display = true }) => !!display)
            .map((action) => (
              <ActionItem
                key={action.id}
                onClick={() => action.onClick()}
                variant={action.variant}
                disabled={action.disabled}
                data-for={action.id}
                data-tip={action.tooltipMessage || ''}
                style={action.optionStyle || {}}
              >
                {action.iconLeft && (
                  <IconLeftWrapper>{action.iconLeft}</IconLeftWrapper>
                )}
                {action.label}
                {action.iconRight && (
                  <IconRightWrapper>{action.iconRight}</IconRightWrapper>
                )}
                {action.disabled && <Tooltip id={action.id} />}
              </ActionItem>
            ))}
        </ActionsListContainer>
      </OutsideClickHandler>
    );
  };

  return (
    <ActionsWrapper>
      <IconWrapper
        onClick={() => {
          setShowActions(true);
        }}
      >
        <EllipsisIcon></EllipsisIcon>
      </IconWrapper>
      {showActions && renderActions()}
    </ActionsWrapper>
  );
};
