import colors from 'constants/colors';

export const CopyPasteIcon: React.FC<{ size?: number; color?: string }> = ({
  size = 16,
  color = colors.gray500,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="left icon">
        <path
          id=" copy-paste"
          d="M4.43388 11.7423V12.0704C4.35277 11.9669 4.31991 11.8611 4.31991 11.7423V2.16858C4.31991 2.04981 4.35277 1.94396 4.43388 1.84049V2.16858V11.7423ZM4.88977 12.3122C4.771 12.3122 4.66515 12.2793 4.56169 12.1982H4.88977H12.412H12.7401C12.6366 12.2793 12.5308 12.3122 12.412 12.3122H4.88977ZM12.9819 11.7423C12.9819 11.8611 12.949 11.9669 12.8679 12.0704V11.7423V2.16858V1.84049C12.949 1.94396 12.9819 2.04981 12.9819 2.16858V11.7423ZM12.412 1.59871C12.5308 1.59871 12.6366 1.63158 12.7401 1.71269H12.412H4.88977H4.56169C4.66515 1.63158 4.771 1.59871 4.88977 1.59871H12.412ZM2.83826 14.2497H10.4859V14.3637H2.83826C2.71949 14.3637 2.61364 14.3308 2.51017 14.2497H2.83826ZM2.38237 14.1219C2.30125 14.0185 2.26839 13.9126 2.26839 13.7938V3.94086H2.38237V13.7938V14.1219Z"
          stroke="#929497"
          stroke-width="0.911785"
        />
      </g>
    </svg>
  );
};
