import styled from 'styled-components';

import {
  BREAKPOINT_DESKTOP_START,
  BREAKPOINT_MOBILE_END,
} from 'constants/breakpoints';
import colors from 'constants/colors';

import {
  DEFAULT_PADDING,
  DEFAULT_SPACING,
  LARGE_WIDTH,
  MOBILE_PAGE_LATERAL_MARGIN,
  PAGE_LATERAL_MARGIN,
  PAGE_TOP_MARGIN,
  REGULAR_WIDTH,
  SECTION_SMALL_BOTTOM_MARGIN,
  SUB_NAV_BOTTOM_MARGIN,
  SUB_NAV_PADDING,
} from './constants';

type PageProps = {
  regular?: boolean;
  large?: boolean;
  withSidePanel?: boolean;
};

type PageBreadcrumbsProps = {
  noPaddingBottom?: boolean;
};

type PageSubtitleProps = {
  height?: number;
  withPaddingRight?: boolean;
  withPaddingBottom?: boolean;
};

type SubNavContainerProps = {
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
  withPaddingLeft?: boolean;
  withBoxShadow?: boolean;
};

type SubNavContainerInDisplayProps = {
  display?: string;
};

type SectionsContainerProps = {
  scrollable?: boolean;
};

type SectionProps = {
  noFlex?: boolean;
  noMargin?: boolean;
  vertical?: boolean;
  smallMarginBottom?: boolean;
};

type SectionHeaderProps = {
  noMarginLeft?: boolean;
  noMarginRight?: boolean;
};
type RowProps = {
  noPaddingTop?: boolean;
  itemGap?: boolean;
};

export const Root = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
`;

export const SideNavContainer = styled.div`
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-right: 1px solid #e5e8eb;
  height: 100vh;
  overflow-y: hidden;
`;

export const PageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: auto;
`;

export const Page = styled.div<PageProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  ${(props) =>
    props.large
      ? `max-width: ${LARGE_WIDTH}`
      : props.regular
        ? `max-width: ${REGULAR_WIDTH}`
        : ''};
  flex-direction: ${(props) => (props.withSidePanel ? 'row' : 'column')};
  min-width: 0;
`;

export const BreadcrumbContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  color: rgb(146, 148, 150);
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  line-height: 18px;
  margin-left: -6px;
  padding: 6px;
  transition: background 120ms ease-in 0s;

  :hover {
    background-color: rgba(55, 53, 57, 0.08);
  }

  :focus,
  :active {
    background-color: rgba(45, 47, 48, 0.1);
  }
`;

export const PageBreadcrumbs = styled.div<PageBreadcrumbsProps>`
  flex-shrink: 0;
  ${(props) =>
    props.noPaddingBottom ? '' : `padding-bottom:${DEFAULT_PADDING}`};
  margin-top: ${PAGE_TOP_MARGIN};
  padding-left: ${PAGE_LATERAL_MARGIN};
`;

export const PageSubtitle = styled.div<PageSubtitleProps>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  min-height: ${(props) => (props.height ? `${props.height}px` : '31px')};
  font-size: 14px;
  line-height: ${(props) => (props.height ? `${props.height}px` : '31px')};
  color: #929497;
  // margin-top: 8px;
  padding-left: ${PAGE_LATERAL_MARGIN};
  ${(props) =>
    props.withPaddingRight ? `padding-right:${PAGE_LATERAL_MARGIN}` : ''};
  ${(props) =>
    props.withPaddingBottom ? `margin-bottom:${DEFAULT_SPACING}` : ''};

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    padding-left: 16px;
  }
`;

export const SubNavContainer = styled.div<SubNavContainerProps>`
  display: flex;
  flex-shrink: 0;
  min-height: 39px;
  border-bottom: 1px solid #d5d8db;
  z-index: 1;

  ${(props) => (props.noMarginTop ? '' : `margin-top: 16px;`)}
  ${(props) =>
    props.noMarginBottom ? '' : `margin-bottom: ${SUB_NAV_BOTTOM_MARGIN};`}
  ${(props) =>
    props.noMarginLeft ? '' : `margin-left: ${PAGE_LATERAL_MARGIN};`}
  ${(props) =>
    props.noMarginRight ? '' : `margin-right: ${PAGE_LATERAL_MARGIN};`}

  ${(props) =>
    props.withPaddingLeft ? `padding-left: ${SUB_NAV_PADDING};` : ''}

  ${(props) =>
    props.withBoxShadow
      ? `box-shadow: 0px 3px 4px rgb(0 0 0 / 10%); margin-bottom: 3px;`
      : ''}

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    margin-bottom: 16px;
  }
`;

export const MobileSubnavContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  min-height: 60px;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.gray300};
  border: 0;
  box-shadow: 0px -5px 6px rgba(15, 15, 15, 0.1);
  bottom: 0;
  left: 0;
  margin: 0;
  position: fixed;
  z-index: 3;
  width: 100%;
  padding: 16px 8px;
`;

export const SubNavContainerInDisplay = styled.div<SubNavContainerInDisplayProps>`
  ${(props) => (props.display ? `display: ${props.display}` : '')};
  flex: 1;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    display: flex;
    flex-direction: row;
  }
`;

export const SectionsContainer = styled.div<SectionsContainerProps>`
  flex: 1 0 0%;
  margin-bottom: 20px;
  ${(props) => (props.scrollable ? 'overflow-y: auto' : '')};
`;

export const Section = styled.div<SectionProps>`
  flex-shrink: 0;
  margin-left: ${(props) => (props.noMargin ? 0 : PAGE_LATERAL_MARGIN)};
  margin-right: ${(props) => (props.noMargin ? 0 : PAGE_LATERAL_MARGIN)};
  margin-bottom: ${(props) => (props.noMargin ? 0 : props.smallMarginBottom ? SECTION_SMALL_BOTTOM_MARGIN : PAGE_LATERAL_MARGIN)};
  ${(props) => (props.noFlex ? '' : 'display: flex;')};
  ${(props) => (props.vertical ? 'flex-direction: column' : '')};

  @media screen and (max-width: ${BREAKPOINT_MOBILE_END}px) {
    margin-left: ${MOBILE_PAGE_LATERAL_MARGIN};
    margin-right: ${MOBILE_PAGE_LATERAL_MARGIN};
  }
`;

export const SectionMobile = styled.div`
  flex-shrink: 0;
  padding: 0 16px;
  padding-bottom: 48px;
`;

export const Row = styled.div<RowProps>`
  display: flex;
  ${(props) => (props.noPaddingTop ? '' : `margin-top:${DEFAULT_SPACING}`)};
  ${(props) => props.itemGap && `gap: ${DEFAULT_SPACING};`}
`;

export const SectionLeft = styled.div.attrs({
  className: 'layout-section-left',
})`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  margin-right: ${DEFAULT_SPACING};
`;

export const SectionRight = styled.div.attrs({
  className: 'layout-section-right',
})`
  flex: 0.3;
  display: flex;
  flex-direction: column;

  .layout-card {
    flex: none;
  }
`;

export const SectionHeader = styled.div<SectionHeaderProps>`
  display: flex;
  flex-shrink: 0;
  margin-bottom: 24px;
  justify-content: space-between;
  ${({ noMarginLeft }) =>
    !noMarginLeft && `margin-left: ${PAGE_LATERAL_MARGIN};`};
  ${({ noMarginRight }) =>
    !noMarginRight && `margin-right: ${PAGE_LATERAL_MARGIN};`};
  margin-bottom: ${DEFAULT_SPACING};
  padding-top: ${DEFAULT_PADDING};

  @media screen and (max-width: ${BREAKPOINT_MOBILE_END}px) {
    margin-left: ${MOBILE_PAGE_LATERAL_MARGIN};
    margin-right: ${MOBILE_PAGE_LATERAL_MARGIN};
  }
`;

export const SectionHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.4;
`;

export const SectionHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  align-items: flex-start;
`;

export const SectionHeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: ${colors.gray850};
`;

export const SectionHeaderSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray500};
`;

export const FullPage = styled.div`
  background: ${colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FullPageBigHeader = styled.div<{ color?: string }>`
  background: ${(props) => (props.color ? props.color : colors.sand50)};
  min-height: 226px;
  display: flex;
  justify-content: center;
`;

export const FullPageSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const FullPageSection = styled.div<{
  vertical?: boolean;
  withPaddingTop?: boolean;
  withPaddingBottom?: boolean;
  borderBottom?: boolean;
  centered?: boolean;
}>`
  width: 660px;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  padding-top: ${(props) => (props.withPaddingTop ? '16px' : '0px')};
  padding-bottom: ${(props) => (props.withPaddingBottom ? '16px' : '0px')};
  border-bottom: ${(props) =>
    props.borderBottom ? '1px solid ' + colors.gray200 : '0px solid'};
  ${(props) => (props.centered ? 'align-items: center;' : '')};
  ${(props) => (props.centered ? 'justify-content: center;' : '')};
`;

export const MainPanelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const MainPanel = styled.div<{
  regular: boolean;
  isSidePanelOpen?: boolean;
}>`
  height: 100%;
  width: 100%;
  ${(props) => (props.regular ? `max-width: ${REGULAR_WIDTH}` : '')};
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 16px;

  @media (min-width: 1381px) and (max-width: 1780px) {
    transition: padding-right 0.2s;

    ${({ isSidePanelOpen, regular }) =>
      isSidePanelOpen &&
      `padding-right: ${
        regular ? `calc(300px - (100% - ${REGULAR_WIDTH}) / 2)` : '284px'
      };
      `}
  }
`;

/**
 * see modules/common-ui/SidePanel
 */
export const SidePanelContainer = styled.div<{ show: boolean }>`
  position: fixed;
  right: 0;
  height: 100%;
  width: 300px;
  opacity: ${(props) => (props.show ? '1' : '0')};
  transform: ${(props) => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  transition: opacity 0.2s, transform 0.2s;

  background: ${colors.white};
  overflow-y: auto;
  padding: 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
`;
