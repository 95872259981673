import colors from 'constants/colors';

export const ConnectIcon: React.FC<{ color?: string }> = ({
  color = colors.gray500,
}) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.14286C14 5.32679 13.0411 6.28571 11.8571 6.28571C11.2438 6.28571 10.6893 6.02857 10.3009 5.59196L6.25625 7.63839C6.275 7.75625 6.28571 7.87679 6.28571 8C6.28571 8.12321 6.275 8.24375 6.25625 8.36161L10.3009 10.3839C10.6893 9.97143 11.2438 9.71429 11.8571 9.71429C13.0411 9.71429 14 10.6732 14 11.8571C14 13.0411 13.0411 14 11.8571 14C10.6732 14 9.71429 13.0411 9.71429 11.8571C9.71429 11.5973 9.75982 11.3241 9.84554 11.1152L5.94286 9.16518C5.55982 9.75446 4.89821 10.1429 4.14286 10.1429C2.95946 10.1429 2 9.18393 2 8C2 6.81607 2.95946 5.85714 4.14286 5.85714C4.89821 5.85714 5.55982 6.24554 5.94286 6.83482L9.84554 4.88482C9.75982 4.65179 9.71429 4.40268 9.71429 4.14286C9.71429 2.95946 10.6732 2 11.8571 2C13.0411 2 14 2.95946 14 4.14286ZM4.11875 9.28571C4.85268 9.28571 5.40446 8.70982 5.40446 8C5.40446 7.29018 4.85268 6.71429 4.11875 6.71429C3.43277 6.71429 2.83304 7.29018 2.83304 8C2.83304 8.70982 3.43277 9.28571 4.11875 9.28571ZM11.8571 2.85714C11.1473 2.85714 10.5714 3.43277 10.5714 4.14286C10.5714 4.85268 11.1473 5.42857 11.8571 5.42857C12.567 5.42857 13.1429 4.85268 13.1429 4.14286C13.1429 3.43277 12.567 2.85714 11.8571 2.85714ZM11.8571 13.1429C12.567 13.1429 13.1429 12.567 13.1429 11.8571C13.1429 11.1473 12.567 10.5714 11.8571 10.5714C11.1473 10.5714 10.5714 11.1473 10.5714 11.8571C10.5714 12.567 11.1473 13.1429 11.8571 13.1429Z"
        fill={color}
      />
    </svg>
  );
};
