import colors from 'constants/colors';

export type AddressPinAltProps = {
  color?: string;
  size?: number;
};

export const AddressPinAlt = ({
  color = colors.gray400,
  size = 16,
}: AddressPinAltProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38152 14.2764C10.7815 12.3564 12.7015 8.98224 12.7015 6.59637C12.7015 4.2105 10.7674 2.27637 8.38152 2.27637C5.99565 2.27637 4.06152 4.2105 4.06152 6.59637C4.06152 8.98224 5.98152 12.3564 8.38152 14.2764ZM8.38175 8.03647C9.17704 8.03647 9.82175 7.39176 9.82175 6.59647C9.82175 5.80118 9.17704 5.15647 8.38175 5.15647C7.58646 5.15647 6.94175 5.80118 6.94175 6.59647C6.94175 7.39176 7.58646 8.03647 8.38175 8.03647Z"
      fill={color}
    />
  </svg>
);
