import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import { Tooltip } from 'modules/common-ui';

import { FleetSegment } from 'modules/analytics/components/AssetFilter/models/FleetSegment';
import { AssetFilterOptions } from 'modules/analytics/components/AssetFilter/types';
import { FilterButton } from './FilterButton';
import { FilterDesktop } from './FilterDesktop';
import { FilterMobile } from './FilterMobile';
import { CalloutOnFilter } from './FilterSelector';
import { FilterWrapper } from './index.css';

export type FilterOption = {
  label: string;
  value: any;
};

export type FilterObject = {
  type: string;
  label: string;
  options: FilterOption[];
  multiselect?: boolean;
  isCustomField?: boolean;
  customFieldId?: number;
};

export type FilterProps = {
  filters: FilterObject[];
  segments?: FleetSegment[] | undefined;
  onSelectSegment?: (segment: FleetSegment | null) => void;
  appliedFilters?: AssetFilterOptions;
  onFilter: (filters: AssetFilterOptions, segmentId?: number) => void;
  small?: boolean;
  alignRight?: boolean;
  tooltip?: string;
  disabled?: boolean;
  loading?: boolean;
  selectedSegmentId?: number | null;
  triggerCreateSegment?: () => void;
  triggerEditSegment?: (fleetSegment: FleetSegment) => void;
  triggerDeleteSegment?: (fleetSegment: FleetSegment) => void;
  calloutOnFilter?: CalloutOnFilter;
};

export type SelectedFilter = {
  type: string;
  isCustomField?: boolean;
  customFieldId?: number;
  value?: any;
};

// eslint-disable-next-line max-statements
export const Filter = ({
  filters,
  segments = undefined,
  appliedFilters = {},
  onFilter,
  small = false,
  alignRight = false,
  tooltip = '',
  disabled = false,
  loading = false,
  selectedSegmentId,
  onSelectSegment,
  triggerCreateSegment,
  triggerEditSegment,
  triggerDeleteSegment,
  calloutOnFilter,
}: FilterProps) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT_DESKTOP_START });

  const [display, setDisplay] = useState(false);
  const [selectedFilters, setSelectedFilters]: [
    SelectedFilter[],
    (filters: SelectedFilter[]) => void,
  ] = useState([{ type: '' }]);

  const addFilter = () => {
    if (!canAddFilter()) {
      return;
    }

    updateSelectedFilters({ filters: [...selectedFilters, { type: '' }] });
  };

  const resetFilters = () => {
    const filtersMap = filters.map(({ type }) => {
      return {
        type,
        value: '',
      };
    });

    updateSelectedFilters({ filters: filtersMap, applyFilters: true });
    onSelectSegment && onSelectSegment(null);
  };

  const applyFilter = useCallback(
    (filtersToApply: SelectedFilter[], segmentId?: number) => {
      const res: AssetFilterOptions = {};

      // transform filter list to object such as
      // {
      //   filterKey: 'value',
      //   filterKey2: [mu, ltiple, values],
      //   brand: [{ id: 1, name: 'brand1'}, {...}],
      //   category: [],
      //   model: 'search'
      // }
      filtersToApply
        .filter((x) => x.type && x.type.length)
        .forEach((f) => {
          const { isCustomField, customFieldId } = f;
          if (isCustomField && customFieldId !== undefined) {
            res.customFieldsV2 = res.customFieldsV2 || [];
            const customFieldIndex = res.customFieldsV2.findIndex(
              (cf) => cf.id === customFieldId,
            );
            const customFieldIsAlreadyApplied = customFieldIndex > -1;
            if (customFieldIsAlreadyApplied) {
              res.customFieldsV2[customFieldIndex].values = f.value;
            } else {
              res.customFieldsV2.push({
                id: customFieldId,
                label:
                  filters.find(
                    (filter) => filter.customFieldId === f.customFieldId,
                  )?.label || '',
                values: f.value,
              });
            }
          } else {
            res[f.type as keyof AssetFilterOptions] = f.value;
          }
        });

      onFilter(res, segmentId);
    },
    [filters, onFilter],
  );

  const activeSegment = useMemo(() => {
    if (!segments || !selectedSegmentId) {
      return null;
    }

    return segments.find((segment) => segment.id === selectedSegmentId) || null;
  }, [segments, selectedSegmentId]);

  const canAddFilter = (): boolean => {
    const selectedFiltersCount = selectedFilters.length;

    return Boolean(
      !selectedFiltersCount ||
        (selectedFilters[selectedFiltersCount - 1].type &&
          selectedFiltersCount !== filters.length),
    );
  };

  const computeFilterTypeOptions = (selectedFilter: SelectedFilter) => {
    return filters
      .filter((filterOption) => {
        const notSelected =
          selectedFilters.findIndex((sf) => sf.type === filterOption.type) ===
          -1;

        return notSelected || filterOption.type === selectedFilter.type;
      })
      .map((filter) => ({
        label: filter.label,
        value: filter.type,
      }));
  };

  const computeFilterObject = (selectedFilter: any) => {
    if (!selectedFilter.type) {
      return { type: '' };
    }

    const filter = filters.find(
      (filterOption) => filterOption.type === selectedFilter.type,
    );

    if (!filter) {
      return {
        type: selectedFilter.type,
        options: [],
      };
    }

    return {
      type: selectedFilter.type,
      value: selectedFilter.value,
      options: filter.options,
      multiselect: filter.multiselect,
    };
  };

  const deleteFilter = (index: number) => {
    if (index > -1) {
      let newFilters = [...selectedFilters];

      newFilters.splice(index, 1);

      if (newFilters.length === 0) {
        newFilters = [{ type: '' }];
      }

      updateSelectedFilters({ filters: newFilters, applyFilters: true });
      onSelectSegment && onSelectSegment(null);
    }

    Tooltip.hide();
  };

  const updateFilterType = (index: number, type: string) => {
    if (index > -1) {
      const newFilters = [...selectedFilters];
      newFilters[index] = { type };

      updateSelectedFilters({ filters: newFilters, applyFilters: true });
      onSelectSegment && onSelectSegment(null);
    }
  };

  const updateFilterValue = (index: number, value: any) => {
    if (index != null && index > -1 && value != null) {
      const newFilters = [...selectedFilters];

      newFilters[index].value = value;

      updateSelectedFilters({ filters: newFilters, applyFilters: true });
      onSelectSegment && onSelectSegment(null);
    }
  };

  const updateSelectedFilters = useCallback(
    ({
      filters,
      applyFilters = false,
    }: {
      filters: SelectedFilter[];
      applyFilters?: boolean;
    }) => {
      setSelectedFilters(filters);

      if (applyFilters) {
        applyFilter(filters);
      }
    },
    [applyFilter],
  );

  // update displayed filters to match with the active segment if any
  // we could have managed to apply filter here but we can then end up in a render loop
  useEffect(() => {
    if (!activeSegment) return;
    setSelectedFilters(activeSegment.filters);
  }, [activeSegment, filters]);

  const applySegment = (segment: FleetSegment) => {
    applyFilter(segment.filters, segment.id);
    onSelectSegment && onSelectSegment(segment);
  };

  return (
    <>
      <FilterWrapper disabled={disabled}>
        <FilterButton
          disabled={disabled}
          small={small}
          activeSegment={activeSegment}
          selectedFilters={selectedFilters}
          show={() => {
            setDisplay(true);
          }}
          tooltip={tooltip}
          loading={loading}
        />
        {isMobile ? (
          <FilterMobile
            display={display}
            segments={segments}
            activeSegment={activeSegment}
            applySegment={applySegment}
            setDisplay={setDisplay}
            filters={filters}
            appliedFilters={appliedFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            resetFilters={resetFilters}
            updateFilterValue={updateFilterValue}
            computeFilterTypeOptions={computeFilterTypeOptions}
            computeFilterObject={computeFilterObject}
          />
        ) : (
          <FilterDesktop
            display={display}
            segments={segments}
            activeSegment={activeSegment}
            applySegment={applySegment}
            appliedFilters={appliedFilters}
            selectedFilters={selectedFilters}
            filters={filters}
            setSelectedFilters={setSelectedFilters}
            loading={loading}
            setDisplay={setDisplay}
            addFilter={addFilter}
            deleteFilter={deleteFilter}
            updateFilterType={updateFilterType}
            updateFilterValue={updateFilterValue}
            alignRight={alignRight}
            triggerCreateSegment={triggerCreateSegment}
            triggerEditSegment={triggerEditSegment!}
            triggerDeleteSegment={triggerDeleteSegment!}
            calloutOnFilter={calloutOnFilter}
          />
        )}
      </FilterWrapper>
    </>
  );
};
