import { fromJS } from 'immutable';

import { getAssetStatusFromGQLType } from 'modules/assets/helpers';
import { capitalize } from 'utils/string';
import { ActionType, ASSET_HEADER } from '../actions';

const initialState = fromJS({
  name: null,
  make: null,
  model: null,
  publicURL: null,
  category: null,
  latestPosition: null,
  enterprise: null,
  source: null,
  displayUsageTab: false,
  favorite: false,
  hasTyreSnapshot: false,
});

/* eslint-disable-next-line complexity, max-statements */
export default function assetHeaderReducer(
  state = initialState,
  action: ActionType,
) {
  if (!action || !action.data) return state;

  switch (action.type) {
    case ASSET_HEADER.RECEIVED_DATA: {
      const { workspace } = action.data.viewer;
      const { asset } = workspace;
      if (!asset) {
        // do something
        return state;
      }
      const { displayUsageTab } = workspace.featureManagement;
      const {
        category,
        enterprise,
        favorite,
        hasTyreSnapshot,
        latestPosition,
        make,
        model,
        name,
        source,
        owned,
        metadata,
        publicURL,
      } = asset;

      const status = metadata?.__typename
        ? getAssetStatusFromGQLType(metadata?.__typename)
        : 'ACTIVE';

      return state
        .set('name', name)
        .set('status', status)
        .set('owned', owned)
        .set('make', capitalize(make))
        .set('model', model)
        .set('publicURL', publicURL)
        .set('enterprise', enterprise)
        .set('latestPosition', latestPosition)
        .set('category', category)
        .set('source', source)
        .set('favorite', favorite)
        .set('hasTyreSnapshot', hasTyreSnapshot)
        .set('displayUsageTab', displayUsageTab);
    }

    case ASSET_HEADER.UPDATE_CACHE: {
      const {
        category,
        enterprise,
        make,
        model,
        name,
        favorite,
        hasTyreSnapshot,
      } = action.data;

      let newState = state;
      if (name !== undefined) {
        newState = newState.set('name', name);
      }
      if (category !== undefined) {
        newState = newState.set('category', category);
      }
      if (make !== undefined) {
        newState = newState.set('make', make);
      }
      if (model !== undefined) {
        newState = newState.set('model', model);
      }
      if (enterprise !== undefined) {
        newState = newState.set('enterprise', enterprise);
      }
      if (favorite !== undefined) {
        newState = newState.set('favorite', favorite);
      }
      if (hasTyreSnapshot !== undefined) {
        newState = newState.set('hasTyreSnapshot', hasTyreSnapshot);
      }

      return newState;
    }

    default:
      return state;
  }
}
