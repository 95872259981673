import i18n from 'i18next';
import { FetchApi } from '../client';
import { UserApi } from './types';

export const userApi = (fetchApi: FetchApi) => ({
  async login(email: string, password: string): Promise<UserApi> {
    return fetchApi
      .post('/login', { email, password })
      .then((response) => {
        if (response.status && response.status === 401) {
          throw new Error(i18n.t('user:user.login.error401'));
        }
        return response;
      })
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : i18n.t('user:user.login.error');
        throw new Error(errorMessage);
      });
  },
  async me(token: string): Promise<UserApi> {
    return fetchApi
      .setToken(token)
      .get('/me')
      .catch((error) => {
        const errorMessage =
          error && error.message
            ? error.message
            : i18n.t('user:user.login.error');
        throw new Error(errorMessage);
      });
  },
  async signup(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    language: string,
    recaptcha: string,
  ): Promise<UserApi> {
    return fetchApi
      .post('/signup', {
        email,
        password,
        firstName,
        lastName,
        language,
        recaptcha,
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to sign up using these credentials. Please try again.`,
            {
              cause: error,
            },
          );
        }
        throw new Error(
          `Unable to sign up using these credentials. Please try again.`,
        );
      });
  },
  async activate(email: string, token: string): Promise<UserApi> {
    return fetchApi.post('/activate', { email, token }).then((a) => {
      if (a.status && a.status === 302) {
        throw new Error('already activated');
      }
      return a;
    });
    // .catch(errorMessage => {
    //   throw new Error(errorMessage)
    // });
  },
  async sendResetToken(
    email: string,
    locale: string,
  ): Promise<{ success: boolean }> {
    return fetchApi
      .post('/send-reset-token', { email, locale })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to reset password using these credentials.. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to reset password using these credentials. Please try again.`,
        );
      });
  },
  async validateResetToken(
    email: string,
    token: string,
  ): Promise<{ success: boolean }> {
    return fetchApi
      .post('/validate-reset-token', { email, token })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to reset password using these credentials. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to reset password using these credentials. Please try again.`,
        );
      });
  },
  async resetPassword(
    email: string,
    password: string,
    token: string,
  ): Promise<UserApi> {
    return fetchApi
      .post('/reset-password', { email, token, password })
      .then(async (response) => {
        if (response.status && response.status === 401) {
          const error = await response.json();
          const errorMessage = error.message || JSON.stringify(error);
          throw new Error(errorMessage);
        }
        return response;
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to reset password. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to reset password using these credentials. Please try again. (${error})`,
        );
      });
  },
  async switchWorkspace(
    token: string,
    payload: {
      workspaceId: number;
    },
  ) {
    return fetchApi.setToken(token).post('/switch-workspace', payload);
  },
  async getJobTitles({
    token,
  }: {
    token: string;
  }): Promise<{ id: number; title: string; description: string }[]> {
    return fetchApi
      .setToken(token)
      .get('/user-jobs')
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to fetch job titles. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to fetch job titles. Please try again. (errorMessage))`,
        );
      });
  },
  async addUserJobTitle({
    token,
    job,
    isCustomJobTitle,
  }: {
    token: string;
    job: string | number;
    isCustomJobTitle: boolean;
  }): Promise<UserApi> {
    return fetchApi
      .setToken(token)
      .post('/add-user-job', {
        job,
        isCustomJobTitle,
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to update Job Title. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to update Job Title. Please try again. (errorMessage))`,
        );
      });
  },
  async createOrganization({
    token,
    name,
    sector,
    isCustomSector,
  }: {
    token: string;
    name: string;
    sector: string | number;
    isCustomSector: boolean;
  }): Promise<UserApi> {
    return fetchApi
      .setToken(token)
      .post('/create-organization', {
        name,
        sector,
        isCustomSector,
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to create organization. Please try again. (${error.message})`,
            { cause: error },
          );
        }

        throw new Error(
          `Unable to create organization. Please try again. (${error})`,
        );
      });
  },
  async getOrganizationSectors({
    token,
  }: {
    token: string;
  }): Promise<{ id: number; name: string; description: string }[]> {
    return fetchApi
      .setToken(token)
      .get('/organization-sectors')
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to fetch organization sectors. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to fetch organization sectors. Please try again. (${error})`,
        );
      });
  },
  async joinOrganizationWithInvitation({
    token,
    invitationKey,
  }: {
    token: string;
    invitationKey: string;
  }): Promise<UserApi> {
    return fetchApi
      .setToken(token)
      .post('/join-organization', {
        invitationKey,
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to accept invitation. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to accept invitation. Please try again. (${error})`,
        );
      });
  },
  async acceptInvitation({
    invitationKey,
    firstName,
    lastName,
    password,
    language,
  }: {
    invitationKey: string;
    firstName: string;
    lastName: string;
    password: string;
    language: string;
  }): Promise<UserApi> {
    return fetchApi
      .post('/accept-invitation', {
        invitationKey,
        firstName,
        lastName,
        password,
        language,
      })
      .catch((error) => {
        if (error instanceof Error) {
          throw new Error(
            `Unable to accept invitation. Please try again. (${error.message})`,
            { cause: error },
          );
        }
        throw new Error(
          `Unable to accept invitation. Please try again. (${error})`,
        );
      });
  },

  async getInvitation({
    invitationKey,
  }: {
    invitationKey: string;
  }): Promise<UserApi> {
    return fetchApi.get(`/invitation/${invitationKey}`).catch((error) => {
      if (error instanceof Error) {
        throw new Error(
          `Unable to get invitation. Please try again. (${error.message})`,
          { cause: error },
        );
      }
      throw new Error(`Unable to get invitation. Please try again. (${error})`);
    });
  },
});
